import React, { FunctionComponent } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";
import { Grid, Cell, Card, CardTitle, CardText } from "react-md";

interface FAQsProps {
	nodes: Array<{
		id: string;
		question: string;
		answer: string;
	}>;
}

const FAQs: FunctionComponent<FAQsProps> = ({ nodes }) => (
	<div>
		<Grid>
			{nodes.map(({ question, answer, id }) => (
				<Cell key={id}>
					<Card>
						<CardTitle title={question} />
						<CardText>
							<div dangerouslySetInnerHTML={{ __html: answer }} />
						</CardText>
					</Card>
				</Cell>
			))}
		</Grid>
	</div>
);

const FAQ_QUERY = graphql`
	query FAQsQuery {
		allFaqsYaml {
			nodes {
				answer
				id
				question

				parent {
					id
				}
			}
		}
	}
`;

const FAQPage: FunctionComponent<{}> = () => {
	const { allFaqsYaml } = useStaticQuery(FAQ_QUERY);
	return (
		<Layout title="Frequently Asked Questions">
			<SEO title="Frequently Asked Questions" />
			<FAQs nodes={allFaqsYaml.nodes} />
		</Layout>
	);
};

export default FAQPage;
